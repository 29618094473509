'use client';

import { useRouter } from 'next/navigation';

import { CommandGroup, CommandItem } from '@/components/ui/command';
import { UserPlusIcon } from '@heroicons/react/16/solid';
import { type Dispatch, type SetStateAction } from 'react';

export function BulkGroup({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const handleSelect = (path: string) => {
    router.push(path);
    setOpen(false);
  };
  return (
    <CommandGroup heading='Bulk'>
      <CommandItem onSelect={() => handleSelect('/bulk/create/tenant')}>
        <UserPlusIcon className='mr-2 size-4' />
        <span>Skapa hyresgäster</span>
      </CommandItem>
    </CommandGroup>
  );
}
