'use client';

import { getSelectItemFromModelName } from '@/components/data-table/custom-tables/select-table/select-items/relation-item-map';
import { getCustomDisplayName } from '@/components/form/dynamic-field/components/relation-field';
import { CommandGroup, CommandItem } from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { type BaseInstance, type ModelName } from '@pigello/pigello-matrix';
import { useRouter } from 'next/navigation';
import type { Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import type { RecentSearch } from './search-group';

export function RecentSearches({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [recentSearches, setRecentSearches] = useLocalStorage<RecentSearch[]>(
    'recentSearches',
    []
  );

  const router = useRouter();

  const renderDisplayName = (
    instance: BaseInstance | undefined,
    modelName: ModelName,
    displayFieldName?: string
  ) => {
    if (!instance) return '';

    const customDisplay = getCustomDisplayName(modelName, instance);

    if (customDisplay) return customDisplay;

    const displayKey = displayFieldName;

    if (displayKey && instance[displayKey as keyof BaseInstance] != null) {
      return instance[displayKey as keyof BaseInstance]?.toString();
    }

    if (instance['customId'] != null) return instance['customId'];

    return instance['id'];
  };

  if (!recentSearches?.length) return null;
  return (
    <CommandGroup
      heading={
        <div className='flex items-center justify-between gap-2'>
          <span>Senast sökt</span>
          <button
            className='transition-colors hover:text-muted-foreground/70'
            onClick={() => setRecentSearches([])}
          >
            Rensa senast sökt
          </button>
        </div>
      }
    >
      {recentSearches?.map(
        ({ inst, url, modelName, displayFieldName, verboseName }) => {
          return (
            <CommandItem
              key={inst.id}
              value={inst.id}
              keywords={[
                renderDisplayName(inst, modelName, displayFieldName),
                verboseName ?? '',
              ]}
              onSelect={() => {
                // Place the chosen search at the top of the list
                setRecentSearches((prev) => [
                  { inst, url, modelName, displayFieldName, verboseName },
                  ...prev.filter((s) => s.inst.id !== inst.id),
                ]);
                router.push(url);
                setOpen(false);
              }}
            >
              <div className='flex w-full'>
                <div
                  className={cn(
                    'mr-4',
                    !getSelectItemFromModelName(modelName).renderItem(inst)
                      .avatar && 'hidden'
                  )}
                >
                  {
                    getSelectItemFromModelName(modelName).renderItem(inst)
                      .avatar
                  }
                </div>
                <div className='w-full'>
                  <div className='flex w-full items-center justify-between'>
                    {verboseName}{' '}
                    {renderDisplayName(inst, modelName, displayFieldName)}{' '}
                    <span>
                      {
                        getSelectItemFromModelName(modelName)?.renderItem(inst)
                          .badge
                      }
                    </span>
                  </div>
                  <div className='whitespace-nowrap text-xs text-muted-foreground'>
                    {
                      getSelectItemFromModelName(modelName)?.renderItem(
                        inst,
                        true
                      ).descriptionItems
                    }
                  </div>
                </div>
              </div>
            </CommandItem>
          );
        }
      )}
    </CommandGroup>
  );
}
