import { getSelectItemFromModelName } from '@/components/data-table/custom-tables/select-table/select-items/relation-item-map';
import { getCustomDisplayName } from '@/components/form/dynamic-field/components/relation-field';
import { Icons } from '@/components/icons';
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from '@/components/ui/command';
import { useOpenDetailModal } from '@/components/ui/detailModalLink';
import { useConfig } from '@/hooks/useConfig';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { ModelNameUrlMap } from '@/lib/urls';
import { cn, raise } from '@/lib/utils';
import { useGetInfiniteList } from '@/requests/hooks';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import type { Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export type RecentSearch = {
  inst: BaseInstance;
  modelName: ModelName;
  url: string;
  displayFieldName?: string;
  verboseName?: string;
};

export function SearchGroup({
  modelName,
  search,
  setOpen,
}: {
  modelName: ModelName;
  search: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRecentSearches] = useLocalStorage<RecentSearch[]>(
    'recentSearches',
    []
  );
  const { config } = useConfig(modelName);
  const url = ModelNameUrlMap[modelName];
  if (!url) {
    raise('No url configured for modelName');
  }
  const navigate = useOpenDetailModal(url);
  const { data, isFetching, hasNextPage, fetchNextPage, error } =
    useGetInfiniteList({
      modelName: modelName,
      queryParams: {
        page: 1,
        pageSize: 25,
        search,
      },
      nested: getSelectItemFromModelName(modelName).nested,
    });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: !!error,
    rootMargin: '0px 0px 100px 0px',
  });

  const renderDisplayName = (instance: BaseInstance | undefined) => {
    if (!instance) return '';

    const customDisplay = getCustomDisplayName(modelName, instance);

    if (customDisplay) return customDisplay;

    const displayKey = config?.displayFieldName;

    if (displayKey && instance[displayKey as keyof BaseInstance] != null) {
      return instance[displayKey as keyof BaseInstance]?.toString();
    }

    if (instance['customId'] != null) return instance['customId'];

    return instance['id'];
  };

  const listData = data?.pages.flatMap((p) => p.list);

  return (
    <CommandGroup
      heading={`Sök bland ${config?.verboseNamePlural.toLowerCase()}`}
    >
      {!isFetching && (
        <CommandEmpty className='flex items-center justify-center gap-2 font-medium text-muted-foreground'>
          <MagnifyingGlassIcon className='size-6 opacity-50' />
          Inga resultat hittades
        </CommandEmpty>
      )}
      {listData?.map((instance) => {
        return (
          <CommandItem
            key={instance.id}
            value={instance.id}
            onSelect={() => {
              let recentUrl = '';

              if (typeof url === 'function') {
                recentUrl = url(instance.id);
              }
              if (typeof url === 'string') {
                recentUrl = url + '/' + instance.id;
              }
              navigate(instance.id);
              setRecentSearches((prev) => {
                // Ensure that the search is not duplicated and there's only five recent searches. Remove the last if there are more than five
                const filtered = prev.filter(
                  ({ inst }) => inst.id !== instance.id
                );

                if (filtered.length >= 5) {
                  filtered.pop();
                }

                return [
                  {
                    inst: instance,
                    url: recentUrl,
                    modelName,
                    displayFieldName: config?.displayFieldName,
                    verboseName: config?.verboseName,
                  },
                  ...filtered,
                ];
              });
              setOpen(false);
            }}
          >
            <div className='flex w-full'>
              <div
                className={cn(
                  'mr-4',
                  !getSelectItemFromModelName(modelName).renderItem(instance)
                    .avatar && 'hidden'
                )}
              >
                {
                  getSelectItemFromModelName(modelName).renderItem(instance)
                    .avatar
                }
              </div>
              <div className='w-full'>
                <div className='flex w-full items-center justify-between'>
                  {renderDisplayName(instance)}{' '}
                  <span>
                    {
                      getSelectItemFromModelName(modelName)?.renderItem(
                        instance
                      ).badge
                    }
                  </span>
                </div>
                <div className='whitespace-nowrap text-xs text-muted-foreground'>
                  {
                    getSelectItemFromModelName(modelName)?.renderItem(
                      instance,
                      true
                    ).descriptionItems
                  }
                </div>
              </div>
            </div>
          </CommandItem>
        );
      })}
      {(isFetching || hasNextPage) && (
        <div
          ref={sentryRef}
          className='flex size-full items-center justify-center'
        >
          <Icons.loader className='my-4 size-6 animate-spin' />
        </div>
      )}
    </CommandGroup>
  );
}
